
<template>
  <div id="user-edit-profile">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      Personal Info
    </h6>
    <div class="row gx-3 gx-lg-4 gy-3">
      <div class="col-md-6">
        <label class="form-label" for="firstName">First name</label>
        <input id="firstName" type="text" class="form-control" v-model="data.first_name"
        :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
        <div v-for="(item, index) in v$.data.first_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-md-6">
        <label class="form-label" for="middleName">Middle name</label>
        <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
        :class="{ 'is-invalid': v$.data.middle_name.$error }"  placeholder="Last Name" name="middle_name" />
        <div v-for="(item, index) in v$.data.middle_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-md-6">
        <label class="form-label" for="LastName">Last name</label>
        <input id="LastName" type="text" class="form-control" v-model="data.last_name"
        :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
        <div v-for="(item, index) in v$.data.last_name.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>  
      </div>
      <div class="col-md-6">
          <label class="form-label" for="inputEmail">Email Address</label>
          <input id="inputEmail" :readonly="!!data.is_verified" label="Email" v-model="data.email"
          :class="{ 'is-invalid': v$.data.email.$error }" type="email" name="email" class="form-control"/>
          <div v-for="(item, index) in v$.data.email.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>  
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputMobile">Mobile</label>
        <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
          :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
          <div v-if="v$.data.mobile.$error"
            class="invalid-feedback">
            <span v-if="v$.data.mobile.required.$message">
              {{ v$.data.mobile.required.$message }}</span>
          </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="">Role</label>
            <select class="form-select" v-model="data.role" :class="{ 'is-invalid': v$.data.role.$error  }"
              name="input-role" placeholder="--select role--">
              <option disabled :value="null" key="-1">-- select role--</option>
              <option :value="role.name" v-for="(role, index) in roleOptions" 
              :key="index">{{$filters.capitalize(role.name)}}</option>
          </select>
          <div v-for="(item, index) in v$.data.role.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label">Status</label>
        <select class="form-select" v-model="data.status" :class="{ 'is-invalid': v$.data.status.$error  }"
            name="input-role" placeholder="--select status--">
            <option disabled :value="null" key="-1">-- select status--</option>
            <option :value="status.value" v-for="(status, index) in statusOptions" 
            :key="index">{{status.label}}</option>
        </select>
        <div v-for="(item, index) in v$.data.status.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6 align-self-end">
        <div class="form-group">
          <label class="d-block">Verification</label>
            <b-form-checkbox v-model="data.is_verified" name="is-verified" switch>
              {{data.is_verified? 'Verified' : 'Not Verified'}}
            </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="my-3">
          <h5 class="fs-15 mb-1">Change profile photo</h5>
          <p class="text-muted">Dimension should be 450 x 450 Max: 100KB.</p>
          <input
              class="form-control d-none"
              id="product-image-input"
              type="file"
              ref="updateProfilePhoto" @change="fileUploadReader"
              accept="image/*"
            />
          <div>
            <span v-if="profileImg" class="">
              <button class="btn me-3 mb-2 btn-sm btn-outline-primary"
              >Selected file: {{profileImg.name}}</button>
              <button @click="resetForm()" class="btn mb-2 btn-sm btn-danger"><i class="bx bx-x"></i></button>
            </span>
            <button v-else  class="btn btn-sm btn-outline-primary" @click="$refs.updateProfilePhoto.click()">Browse file</button>
          </div>
        </div>
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Address & Polling Unit
    </h6>
    <div class="row g-3">        
      <div class="col-12">
        <label for="address" class="form-label"> Address </label>
        <input v-model="data.address"
          id="address" name="address" :class="{ 'is-invalid': v$.data.address.$error }"
          class="form-control" type="text" placeholder="Address">
          <div v-for="(item, index) in v$.data.address.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div>  
      </div>
      <div class="col-md-4">
        <label for="local_government_id" class="form-label">Local Government Area</label>
        <select @change="lgaChange()" v-model="data.local_government_id" 
        name="local_government_id" id="local_government_id" class="form-select"
        :class="{ 'is-invalid': v$.data.local_government_id.$error }" >
          <option disabled value="" >--choose LGA--</option>
          <option v-for="(lga, lgIndex) in lgaOptions" :key="lgIndex" 
          :value="lga.id">{{lga.name}}</option>
        </select>
          <div v-for="(item, index) in v$.data.local_government_id.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-md-4">
        <label for="registration_area_id" class="form-label">Ward</label>
        <select @change="wardChange()" v-model="data.registration_area_id" name="registration_area_id" id="registration_area_id" class="form-select"
        :class="{ 'is-invalid': v$.data.registration_area_id.$error }" >
          <option disabled value="" >--choose ward--</option>
          <option v-for="(ward, wIndex) in wardOptions" :key="wIndex" 
          :value="ward.id">{{ward.name}}</option>
        </select>
          <div v-for="(item, index) in v$.data.registration_area_id.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-md-4">
        <label for="polling_unit_id" class="form-label">Polling Unit</label>
        <select v-model="data.polling_unit_id" name="polling_unit_id" id="polling_unit_id" class="form-select"
        :class="{ 'is-invalid': v$.data.polling_unit_id.$error }" >
          <option disabled value="" >--choose ward--</option>
          <option v-for="(unit, uIndex) in unitOptions" :key="uIndex" 
          :value="unit.id">{{unit.name}}</option>
        </select>
        <div v-for="(item, index) in v$.data.polling_unit_id.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Identity Info
    </h6>
    <div class="row g-3">        
      <div class="col-sm-6 col-lg-4">
        <label class="form-label" for="nin_number">NIN number</label>
        <input v-model="data.nin" id="nin_number" class="form-control" type="text"
          placeholder="NIN number" name="nin"
          :class="{ 'is-invalid': v$.data.nin.$error }">
        <div v-for="(item, index) in v$.data.nin.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-sm-6 col-lg-4">
        <label class="form-label" for="bvn_number">BVN number</label>
        <input v-model="data.bvn" id="bvn_number" class="form-control" type="text"
          placeholder="BVN number" name="bvn"
          :class="{ 'is-invalid': v$.data.bvn.$error }" >
          <div v-for="(item, index) in v$.data.bvn.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-sm-12 col-lg-4">
        <label class="form-label" for="vin_number">Voter Identification Number</label>
        <input v-model="data.vin" id="vin_number" class="form-control" type="text"
          placeholder="VIN" name="vin"
          :class="{ 'is-invalid': v$.data.vin.$error }">
          <div v-for="(item, index) in v$.data.vin.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Bank Account
    </h6>
    <div class="row g-3 mb-5">        
      <div class="col-12">
        <label class="form-label" for="account_name">Account name</label>
        <input id="account_name" type="text" class="form-control" 
        v-model="data.account_name"  placeholder="Account name" name="account_name"
        :class="{ 'is-invalid': v$.data.account_name.$error }"  /> 
        <div v-for="(item, index) in v$.data.account_name.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-sm-6">
        <label class="form-label" for="account_number">Account number</label>
        <input id="account_number" type="text" class="form-control" v-model="data.account_number"
        placeholder="Account number" name="account_number"
        :class="{ 'is-invalid': v$.data.account_number.$error }"  />
        <div v-for="(item, index) in v$.data.account_number.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
      <div class="col-sm-6">
        <label class="form-label" for="bank_id">Bank name</label>
        <select v-model="data.bank_id" name="bank_id" id="bank_id" class="form-select"
        :class="{ 'is-invalid': v$.data.bank_id.$error }" >
          <option disabled value="" >--select bank--</option>
          <option v-for="(bank, bIndex) in bankOptions" :key="bIndex" 
          :value="bank.id">{{bank.name}}</option>
        </select>
        <div v-for="(item, index) in v$.data.bank_id.$errors"
            :key="index" class="invalid-feedback">
            <span v-if="item.$message">{{ item.$message }}</span>
          </div> 
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      statusOptions: [
        {label: "Active", value:'active'},
        {label: "Deactivated", value:'deactivated'},
        {label: "Blocked", value:'blocked'}
      ],
      data: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        role: "",
        status: "",
        is_verified: false,

        address:"",
        local_government_id: "",
        registration_area_id: "",
        polling_unit_id:"",

        nin: "",
        bvn: "",
        vin: "",

        bank_id: "",
        account_name:"",
        account_number:"",
      },
      wardOptions:[],
      unitOptions:[],
      profileImg:null
    }
  },
  validations() {
    return { 
      data:{
        first_name: {
          required: helpers.withMessage("First name is required", required),
        },
        middle_name: {
          required: helpers.withMessage("Middle name is required", required),
        },
        last_name: {
          required: helpers.withMessage("Last name is required", required),
        },
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter valid email", email),
        },
        mobile: {
          required: helpers.withMessage("Phone number is required", required),
        },
        status: {
          required: helpers.withMessage("Select status", required),
        },
        role: {
          required: helpers.withMessage("User role is required", required),
        },

        address: {  required: required },

        local_government_id: {
          required: helpers.withMessage("Select Local Government Area", required),
        },
        registration_area_id: {
          required: helpers.withMessage("Select ward", required),
        },
        polling_unit_id: {
          required: helpers.withMessage("The polling unit is required", required),
        },

        nin: {
          required: helpers.withMessage("NIN is required", required),
          minLength: helpers.withMessage("minimum of 11 digits", minLength(11))
        },
        bvn: {
          required: helpers.withMessage("BVN is required", required),
          minLength: helpers.withMessage("minimum of 11 digits", minLength(11))
        },
        vin: {
          required: helpers.withMessage("Voters ID is required", required),
        },

        account_name: { required: required },
        account_number: {
            required: required,
            minLength: helpers.withMessage("minimum of 10 digits", minLength(10))
        },
        bank_id: {
          required: helpers.withMessage("Bank account is required", required),
        },
      }
    }
  },
  computed: {
    user(){
      return this.$store.state.userList.user
    },
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
    bankOptions(){
      return this.$store.state.banks
    },
    lgaOptions(){
      return this.$store.state.localGovernments
    },
  },
  methods: {
    updateUser() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        if(this.profileImg){formData.append('image_file', this.profileImg);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${this.user.id}/update`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.resetForm()
            }
        })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.user)
    },
    fileUploadReader(input) {
      if (input.target.files && input.target.files[0]) {
        this.profileImg = input.target.files[0]
      }
    },
    resetForm() {
      this.profileImg = null
    },
    lgaChange(check = true){
      if(check){ this.data.registration_area_id = this.data.polling_unit_id = ""; }
      this.wardOptions = this.unitOptions = [];
      if(!this.data.local_government_id){
        return
      }
      this.$http.get(`${this.absoluteUrl()}/api/wards?local_government_id=${this.data.local_government_id}`)
        .then((response) => {
        if(response.data.success){
          this.wardOptions = response.data.data
        }
      })
    },
    wardChange(check = true){
      if(check){ this.data.polling_unit_id = ""; }
      this.unitOptions = [];
      if(!this.data.local_government_id || !this.data.registration_area_id){
        return
      }
      this.$http.get(`${this.absoluteUrl()}/api/polling-units?local_government_id=${this.data.local_government_id}&registration_area_id=${this.data.registration_area_id}`)
        .then((response) => {
        if(response.data.success){
          this.unitOptions = response.data.data
        }
      })
    },
  },
  created(){
    this.patchUser()
    this.lgaChange(false); this.wardChange(false)
  },
}
</script>
