<template>
<div  class="">
  <h6 class="mb-3 text-uppercase bg-light p-2">
      Password
  </h6>
  <div class="row g-3" >
    <div class="col-12">
      <label class="form-label" for="new-password">New Password</label>
      <input ref="password"  v-model="data.password" id="new-password" type="password"
        :class="{ 'is-invalid': v$.data.password.$error }"  class="form-control" placeholder="Enter new password" name="password" />
        <div v-for="(item, index) in v$.data.password.$errors" :key="index"
          class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
    </div>
    <div class="col-12">
      <label class="form-label" for="confirm-password">Confirm Password</label>
      <input v-model="data.password_confirmation" id="confirm-password" type="password"
        :class="{ 'is-invalid': v$.data.password_confirmation.$error }" class="form-control" placeholder="Confirm Password" name="confirm_password"  />
      <div v-for="(item, index) in v$.data.password_confirmation.$errors" :key="index"
        class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-12">
      <div class="text-end">
        <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
           Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { required, helpers, minLength, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      data: {
        password: "",
        password_confirmation: "",
      },
    }
  },
  validations: {
    data:{
      password: {
        required: helpers.withMessage("Password is required", required),
        minLength: helpers.withMessage("minimum of 6 characters", minLength(6))
      },
      password_confirmation: {
        required: helpers.withMessage("Password confirmation is required", required)
      },
    }
  },
  methods:{
    updateUser() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/users/${this.$route.params.userId}/update-password`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.resetForm()
            }
        })
      });
    },
    resetForm() {
      this.data.old_password = this.data.password = this.data.password_confirmation = "";
      this.v$.$reset()
    },

  },
}
</script>
